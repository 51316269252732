<template>
  <div class="container app">
    <div class="row app-one">
      <div class="col-sm-4 side">
        <div class="side-one">
          <div class="row heading">
            <div class="col-sm-2 col-xs-3 heading-avatar">
              <div class="heading-avatar-icon">
                <img src="https://bootdey.com/img/Content/avatar/avatar1.png" />
              </div>
            </div>
            <div class="col-sm-8 col-xs-7 heading-name">
              <a class="heading-name-meta">Operador</a>
              <span class="heading-online">{{ $route.params.email }}</span>
            </div>
            <div class="col-sm-1 col-xs-1 heading-dot pull-right">
              <i
                class="fa fa-ellipsis-v fa-2x pull-right"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div class="row sideBar">
            <div class="row sideBar-body" v-on:click="selectContact(item.contato.id_contato)" v-for="item in contatos" :key="item.id">
              <div class="col-sm-2 col-xs-2 sideBar-avatar">
                <div class="avatar-icon">
                  <img src="https://bootdey.com/img/Content/avatar/avatar6.png" />
                </div>
              </div>
              <div class="col-sm-10 col-xs-10 sideBar-main">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <a class="heading-name-meta">{{item.contato.id_contato | formatPhone}} <span style="float:right;font-size:12px;font-weight:initial;width:initial;color: #93918f;">{{((item.mensagem) ? item.data_contato : item.data_contato) | formatDate('DD/MM/YYYY')}}</span></a>
                    <span class="heading-online" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block;">{{(item.mensagem) ? item.mensagem.json.body : ''}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8 conversation">
        <div class="row heading">
          <div class="col-sm-2 col-md-1 col-xs-3 heading-avatar">
            <div class="heading-avatar-icon">
              <img src="https://bootdey.com/img/Content/avatar/avatar6.png" />
            </div>
          </div>
          <div class="col-sm-8 col-xs-7 heading-name">
            <a class="heading-name-meta">Cliente</a>
            <span class="heading-online">{{ $route.params.id | formatPhone }}</span>
          </div>
          <div class="col-sm-1 col-xs-1 heading-dot pull-right">
            <i class="fa fa-ellipsis-v fa-2x pull-right" aria-hidden="true"></i>
          </div>
        </div>
        <div class="row message" v-chat-scroll="{smooth: true}">
          <div class="row message-previous">
            <div class="col-sm-12 previous">
            </div>
          </div>
          <div class="row message-body" v-for="item in chatMessages" :key="item.id">
            <div class="col-sm-12" v-bind:class="'message-main-' + item.direcao">
              <div class="" v-bind:class="item.direcao" v-if="item.tipo == 'text'">
                <div class="message-text" v-html="$options.filters.formatText(item.json.body)"></div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'image'">
                <div class="message-text"><img :src="$baseApi + '/api/mensagem/media/' + item.id_mensagem" style="width: 100%;"/></div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'voice'">
                <div class="message-text">
                   <audio  width="320" controls>
                    <source :src="$baseApi + '/api/mensagem/media/' + item.id_mensagem" type="audio/ogg">
                  </audio> 
                </div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'video'">
                <div class="message-text">
                  <video width="320" height="240" controls>
                    <source :src="$baseApi + '/api/mensagem/media/' + item.id_mensagem" type="video/mp4">
                  </video> 
                </div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'audio'">
                <div class="message-text">
                  <audio  width="320" controls>
                    <source :src="$baseApi + '/api/mensagem/media/' + item.id_mensagem" type="video/mp4">
                  </audio>
                </div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'document'">
                <div class="message-text">
                  <a :href="$baseApi + '/api/mensagem/media/' + item.id_mensagem" target="_blank" :download="item.json.filename" :title="item.json.caption">
                    <div class="info-box">                      
                      <span class="info-box-icon bg-aqua"><i class="fa fa-cloud-download"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text">{{item.json.filename}}</span>
                      </div>                      
                    </div>
                  </a>
                </div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
            </div>
          </div>
          <div class="row message-previous">
            <div class="col-sm-12 previous">
            </div>
          </div>
        </div>
        <div class="row reply">
          <div class="col-sm-1 col-xs-1 reply-emojis" v-on:click="openFile()">
            <i class="fas fa-paperclip fa-2x" aria-hidden="true"></i>
          </div>
          <form v-on:submit.prevent="sendMessage">
            <div class="col-sm-10 col-xs-10 reply-main">
              <input type="text" class="form-control" rows="1" v-model="mensagem" placeholder="Digite sua mensagem..."/>
            </div>
            <div class="col-sm-1 col-xs-1 reply-send" v-on:click="sendMessage">
              <i class="fas fa-paper-plane fa-2x" aria-hidden="true"></i>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="fileModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">Enviar Arquivos</h4>
          </div>
          <div class="modal-body">
             <input class="form-control" type="file" name="file" ref="file" accept="audio/aac,audio/mp4,audio/amr,audio/mpeg,audio/ogg; codecs=opus,video/ogg,image/jpeg,image/png,image/webp,video/mp4,video/3gpp,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/zip"/>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-sm" v-on:click="sendFile()">Enviar</button>
            <button type="button" class="btn btn-default btn-sm" data-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chat from "@/controllers/chat.controller";
import contato from "@/controllers/contato.controller";
import toastr from "@/utils/toastr";
import moment from "moment";

import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';

Vue.use(VueChatScroll);

export default {
  name: "Login",
  data: function () {
    return {
      contatos: [],
      chatMessages: [],
      mensagem: ""
    };
  },
  filters: {
    formatDate: function (value, formato) {
      if (!value) return '';

      if(!formato)
        formato = 'DD/MM/YYYY HH:mm';

      return moment( value ).calendar( null, {
          lastWeek: formato,
          lastDay:  formato,
          nextDay:  formato,
          nextWeek: formato,
          sameDay:  'HH:mm',
          sameElse: function () {
              return formato;
          }
      });
    },
    formatPhone: function (value) {
        if (!value) return '';

        return value.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
    },
    formatText: function (value) {
        if (!value) return '';

        var transform = [
          {
            regexp: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig,
            result: '<a href="{match}" target="_blank">{match}</a>'
          },
          {
            regexp: /_(.*?)_/ig,
            result: '<i>{match}</i>'
          },
          {
            regexp: /\*(.*?)\*/ig,
            result: '<b>{match}</b>'
          },
          {
            regexp: /~(.*?)~/ig,
            result: '<strike>{match}</strike>'
          }
        ];

        var textoFormatado = value;

        $.each(transform, function(key, obj) {
          textoFormatado = textoFormatado.replace(obj.regexp, function(match, part) {
            return obj.result.replace(/\{match\}/g, part);
          });
        });

        return textoFormatado;
    }
  },
  methods: {
    selectContact: function (contato) {
      this.$router.push({ name: 'chat', params: { email: this.$route.params.email, id: contato } });
      this.getMessages();
    },
    sendMessage: function () {
      if (!this.mensagem) return;

      chat.sendMessage({operador: this.$route.params.email, id_contato: this.$route.params.id, tipo: "text", conteudo: JSON.stringify({ body: this.mensagem}), direcao: "sender"})
        .then((response) => {
          if (!response.data)
            return;

          this.mensagem = "";
          this.getMessages();
        })
        .catch((e) => {
          toastr.error(e);
        });
    },
    getMessages: function () {
      if(!this.$route.params.email)
        return;

      chat
        .getMessages({operador: this.$route.params.email, id_contato: this.$route.params.id, mensagem: this.mensagem, direcao: "sender"})
        .then((response) => {

          this.chatMessages = response.data.result.mensagens;

          this.chatMessages.forEach(mensagem => {
            if(mensagem.status_mensagem != "read" && mensagem.direcao == "receiver")
              chat.readMessage({ id_mensagem: mensagem.id_mensagem })
          });
        })
        .catch((e) => {
          toastr.error(e);
        });
    },
    openFile: function() {
      $('#fileModal').modal('show');
    },
    sendFile: function() {
      let dataForm = new FormData();
      dataForm.append(`id_contato`, this.$route.params.id);
      dataForm.append(`operador`, this.$route.params.email);
      dataForm.append(`file`, this.$refs.file.files[0]);

      chat.sendFile(dataForm)
        .then((response) => {
          if (!response.data.success)
          {
            toastr.error(response.data.error)
            return;
          }
          
          this.getMessages();
        })
        .catch((e) => {
          toastr.error(e);
        });

      $('#fileModal').modal('hide');
    },
  },
  created: function () {

    if(this.$route.params.email && this.$route.params.id)
    {
      contato.setContato({ operador: this.$route.params.email, id_contato: this.$route.params.id})
        .then((response) => {
          if (!response.data)
            return;
        })
        .catch((e) => {
          toastr.error(e);
        });
    }

    if(this.$route.params.email)
    {
      contato.getContatos({ operador: this.$route.params.email })
        .then((response) => {
          if (!response.data)
            return;

          this.contatos = response.data.result.slice(0, 25);
        })
        .catch((e) => {
          toastr.error(e);
        });
    }

    this.getMessages();
    setInterval(this.getMessages, 5000);
  },
};
</script>

<style scoped>
.message{
  background-image: url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png);
  background-size: 60%;
}

.contact-status {
    position: absolute;
    left: 0;
    margin: -2px 0 0 -2px;
    width: 10px;
    height: 10px !important;
    border-radius: 50%;
    border: 1px solid #2c3e50;
    background: #95a5a6;
}

.opaco{
  opacity: 0.5;
}

span.online {
  background: #2ecc71;
}
span.away {
  background: #f1c40f;
}
span.busy {
  background: #e74c3c;
}

.icon-write:before {
  content: "\f00c";
  margin-left: 2px;
}

.icon-send:before {
  content: "\f00c";
  margin-left: 2px;
}

.icon-delivered:before {
  content: "\f560";
  margin-left: 2px;
}

.icon-read:before {
  content: "\f560";
  color: #4FC3F7;
  margin-left: 2px;
}

.icon-failed:before {
  content: "\f06a";
  color: #ec1111;
  margin-left: 2px;
}

.info-box .info-box-icon {
  border-radius: .25rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size:25px;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width:50px;
  padding: 10px;
}

.info-box {
  box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
  border-radius: .25rem;
  background-color: rgba(0, 0, 0, 0.04);
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  padding: .5rem;
  position: relative;
  width: 320px;
}

.info-box-icon {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  display: block;
  float: left;
  width: 90px;
  text-align: center;
  font-size: 45px;
  line-height: 90px;
  background: rgba(0,0,0,0.2);
    background-color: rgba(0, 0, 0, 0.2);
}

.info-box-content {
  padding: 5px 10px;
}

.info-box-text{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 250px;
  display: block;
}

.message-text a {
  color: black;
  text-decoration: none;
}
</style>