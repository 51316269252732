import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import "@/assets/custom.css";

Vue.config.productionTip = false;

Vue.prototype.$baseApi = process.env.NODE_ENV === "development" ? "https://localhost:44354" : "https://api.drykoadmin.com";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');