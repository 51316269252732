import Vue from 'vue';
import VueRouter from 'vue-router';
import Chat from '@/views/Chat';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Chat,
    children: [
      {
        path: ':email/:id',
        name: 'chat'
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: "active",
  base: process.env.BASE_URL,
  routes
});

export default router;