import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || ""
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    LOG_IN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    LOG_OUT(state) {
      state.token = null;
      localStorage.removeItem('token');
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    logOut({ commit }) {
      commit('LOG_OUT');
    }
  },
  getters: {
    getToken(state) {
      return state.token;
    }
  },
  modules: {
  }
});